import * as React from "react"
import { PageProps, Link, graphql, useStaticQuery } from "gatsby"
import ReactMarkdown from 'react-markdown'

import Layout from "../components/layout"
import Seo from "../components/seo"
import NavBar from "../components/navBar/navBar"

function CavingHutPage() {
  const { strapiCavingHut } = useStaticQuery(graphql`
  {
    strapiCavingHut {
      description {
        data {
          description
        }
      }
    }
  }`)

  return (
    <Layout>
      <Seo title="Caving Hut - Caerllwyn Cottage" />
      <NavBar />
    <ReactMarkdown>{strapiCavingHut.description.data.description}</ReactMarkdown>
    </Layout>
  )
}

export default CavingHutPage
